import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import Grid from "../components/grid"
import { EventsTeaser } from "../components/eventsTeasers"
import { NewsTeaser } from "../components/newsTeaser"

const ShowAllLink = styled(Link) `
  display: block;
  padding: 5px 0 0 7px;

  &:hover {
    color: var(--primary-color);
  }
`

function getCurrentDate() {
  const now = new Date()

  let month = now.getMonth() + 1
  if (month < 10) {
    month = "0" + month
  }

  return `${now.getFullYear()}-${month}-${now.getDate()}`
}

export default ({ data }) => {
  const { news } = data

  const [events, setEvents] = useState()

  useEffect(() => {
    ;(async () => {
      const date = getCurrentDate()
      const query = `* | [ _type == "event" && date > "${date}"] |  order(date) | [0..5]`
      const response = await fetch(
        `https://59662g5w.apicdn.sanity.io/v1/data/query/production?query=${encodeURIComponent(
          query
        )}`
      )
      const eventData = await response.json()
      if (eventData.result) {
        setEvents(eventData.result)
      }
    })()
  }, [])

  return (
    <>
      <SEO title="Home" />
      <h1>OLG Murten</h1>

      <Grid columns="2fr 1fr">
        <div>
          <NewsTeaser
            news={
              news && news.edges && news.edges && news.edges.map(_ => _.node)
            }
            title="Neuigkeiten"
          />
          <ShowAllLink to="/news">Alle Neuigkeiten</ShowAllLink>
        </div>
        <div>
          <h2>Termine</h2>
          {events && <EventsTeaser events={events} />}
          <ShowAllLink to="/events">Alle Termine</ShowAllLink>
        </div>
      </Grid>
    </>
  )
}

export const query = graphql`
  query {
    news: allSanityNews(sort: { fields: publishedAt, order: DESC }, limit: 4) {
      edges {
        node {
          _id
          title
          abstract
          slug {
            current
          }
          publishedAt(formatString: "DD.MM.YYYY")

          mainImage {
            hotspot {
              x
              y
            }
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`
